<template>
<div class="">
  <div class="nk-block-head nk-block-head-sm pb-2">
      <div class="nk-block-between">
          <div class="nk-block-head-content">
              <h3 class="nk-block-title page-title">CRM</h3>
              <div class="nk-block-des text-soft">
                  <p>Showing data for 2021</p>
              </div>
          </div><!-- .nk-block-head-content -->
          <div class="nk-block-head-content">
              <div class="toggle-wrap nk-block-tools-toggle">
                  <a href="#" class="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                  <div class="toggle-expand-content" data-content="pageMenu">
                      <ul class="nk-block-tools g-3">
                          <!-- <li><a href="#" class="btn btn-white btn-outline-light"><em class="icon ni ni-download-cloud"></em><span>Export</span></a></li> -->
                      </ul>
                  </div>
              </div><!-- .toggle-wrap -->
          </div><!-- .nk-block-head-content -->
      </div><!-- .nk-block-between -->
  </div><!-- .nk-block-head -->
  <div class="nk-block">
    <div class="card card-bordered">
      <div class="row">
        <div class="col-12">
          <accountsAdded/>
        </div>
      </div>
    </div>
    <div class="card card-bordered">
      <div class="row">
        <div class="col-12">
          <contactsAdded/>
        </div>
      </div>
    </div>
    <div class="card card-bordered">
      <div class="row">
        <div class="col-12">
          <leadsAdded/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import accountsAdded from '@/components/accounts/dashboard/accountsAdded.vue'
import contactsAdded from '@/components/contacts/dashboard/contactsAdded.vue'
import leadsAdded from '@/components/leads/dashboard/leadsAdded.vue'

export default {
  components: { accountsAdded, contactsAdded, leadsAdded },
  created() {
    this.getView()
  },
  methods: {
    getView() {
            this.$http.get('/ViewAccess/crm')
            .then(() => {
            })
            .catch(() => {
            })
        },
  }
}
</script>

<style>

</style>