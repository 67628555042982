<template>
    <div class="row" v-if="loaded">
        <div class="col-12">
            <div class="card-inner">
                <div class="card-title-group align-start mb-2">
                    <div class="card-title">
                        <h5 class="title">Contacts Statistics</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>
                
                    <div class="col-8">
                        <div class="row">
                            <div class="col-6">
                                <div class="card card-bordered" style="width: 100%">
                                    <div class="card-inner">
                                        <div class="card-title-group align-start mb-2">
                                            <div class="card-title">
                                                <h6 class="title">Last Month Contacts Added</h6>
                                            </div>
                                        </div>
                                        <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                            <div class="nk-sale-data">
                                                <span class="amount">{{ contactsAddedSummary.lastMonthCount }}</span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card card-bordered" style="width: 100%">
                                    <div class="card-inner">
                                        <div class="card-title-group align-start mb-2">
                                            <div class="card-title">
                                                <h6 class="title">Last Year Contacts Added</h6>
                                            </div>
                                        </div>
                                        <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                            <div class="nk-sale-data">
                                                <span class="amount">{{ contactsAddedSummary.lastYearCount }}</span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card-bordered" style="width: 100%">
                                    <div class="card-inner">
                                        <div class="card-title-group align-start mb-2">
                                            <div class="card-title">
                                                <h6 class="title">Contacts Added This Month</h6>
                                            </div>
                                        </div>
                                        <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                            <div class="nk-sale-data">
                                                <span class="amount" :style="{'color': contactsAddedSummary.monthCount >= contactsAddedSummary.lastMonthCount ? '#4BB543' : 'red'}">{{ contactsAddedSummary.monthCount }}</span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card-bordered" style="width: 100%">
                                    <div class="card-inner">
                                        <div class="card-title-group align-start mb-2">
                                            <div class="card-title">
                                                <h6 class="title">Contacts Added This Year</h6>
                                            </div>
                                        </div>
                                        <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                            <div class="nk-sale-data">
                                                <span class="amount" :style="{'color': contactsAddedSummary.yearCount >= contactsAddedSummary.lastYearCount ? '#4BB543' : 'red'}">{{ contactsAddedSummary.yearCount }}</span>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
export default {
    data() {
        return {
            contactsAddedSummary: null,
            contactsAdded: [],
            loaded: false,
            series: [{
                name: 'Contacts Added',
                data: []
            }],
            chartOptions: {
                chart: {
                    height: 'auto',
                    width: 'auto',
                    type: 'area',
                    toolbar: {
                    show: false
                    },
                    dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    marker: {
                        show: false,
                    }
                },
                markers: {
                    colors: ['#FF0000'],
                },
                stroke: {
                    curve: 'smooth',
                    colors: ['#FF0000'],
                },
                fill: {
                    colors: ['#FF0000'],
                },
                xaxis: {
                    categories: [],
                    title: {
                    text: 'Month'
                    }
                },
                yaxis: {
                    title: {
                    text: 'Contacts'
                    },
                    min: 0,
                    max: 10,
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                    },
                },
            }
        }
    },
    created() {
        this.getAccountsAdded()
        this.getContactsAddedSummary()
    },
    methods:{
        getAccountsAdded() {
            this.$http.get('/contacts/get_ContactsAdded/')
            .then((response) => {
                this.contactsAdded = response.data
                this.series[0].data = response.data.data
                this.chartOptions.xaxis.categories = response.data.dateNames
                this.loaded = true
            })
        },
        getContactsAddedSummary() {
            this.$http.get('/widgets/Get_ContactsAddedSummary/')
            .then((response) => {
                this.contactsAddedSummary = response.data
            })
        }
    }
}
</script>

<style scoped>
.card-bordered {
    width: 600px;
    padding: 10px;
}
</style>