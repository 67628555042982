<template>
    <div class="row" v-if="loaded">
        <div class="col-12">
            <div class="card-inner">
                <div class="card-title-group align-start mb-2">
                    <div class="card-title">
                        <h5 class="title">Accounts Statistics</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <apexchart :options="chartOptions" :series="series"></apexchart>
                    </div>
                
                    <div class="col-8">
                        <div class="row">
                            <div class="col-6">
                                <div class="card card-bordered" style="width: 100%">
                                    <div class="card-inner">
                                        <div class="card-title-group align-start mb-2">
                                            <div class="card-title">
                                                <h6 class="title">Last Month Accounts Added</h6>
                                            </div>
                                        </div>
                                        <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                            <div class="nk-sale-data">
                                                <span class="amount">{{ accountsAddedSummary.lastMonthCount }}</span>
                                                <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>2.45%</span>since last week</span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card card-bordered" style="width: 100%">
                                    <div class="card-inner">
                                        <div class="card-title-group align-start mb-2">
                                            <div class="card-title">
                                                <h6 class="title">Last Year Accounts Added</h6>
                                            </div>
                                        </div>
                                        <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                            <div class="nk-sale-data">
                                                <span class="amount">{{ accountsAddedSummary.lastYearCount }}</span>
                                                <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>2.45%</span>since last week</span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card-bordered" style="width: 100%">
                                    <div class="card-inner">
                                        <div class="card-title-group align-start mb-2">
                                            <div class="card-title">
                                                <h6 class="title">Accounts Added This Month</h6>
                                            </div>
                                        </div>
                                        <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                            <div class="nk-sale-data">
                                                <span class="amount" :style="{'color': accountsAddedSummary.monthCount >= accountsAddedSummary.lastMonthCount ? '#4BB543' : 'red'}">{{ accountsAddedSummary.monthCount }}</span>
                                                <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>2.45%</span>since last week</span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="card-bordered" style="width: 100%">
                                    <div class="card-inner">
                                        <div class="card-title-group align-start mb-2">
                                            <div class="card-title">
                                                <h6 class="title">Accounts Added This Year</h6>
                                            </div>
                                        </div>
                                        <div class="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                            <div class="nk-sale-data">
                                                <span class="amount" :style="{'color': accountsAddedSummary.yearCount >= accountsAddedSummary.lastYearCount ? '#4BB543' : 'red'}">{{ accountsAddedSummary.yearCount}}</span>
                                                <!-- <span class="sub-title"><span class="change up text-success"><em class="icon ni ni-arrow-long-up"></em>2.45%</span>since last week</span> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            accountsAddedSummary: null,
            accountsAdded: [],
            loaded: false,
            series: [{
                name: 'Accounts Added',
                data: []
            }],
            chartOptions: {
                chart: {
                    height: 'auto',
                    width: 'auto',
                    type: 'area',
                    toolbar: {
                    show: false
                    },
                    dropShadow: {
                    enabled: true,
                    color: '#000',
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                tooltip: {
                    marker: {
                        show: false,
                    }
                },
                xaxis: {
                    categories: [],
                    title: {
                    text: 'Month'
                    }
                },
                yaxis: {
                    title: {
                    text: 'Accounts'
                    },
                    min: 0,
                    max: 10,
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                    },
                },
            }
        }
    },
    created() {
        this.getAccountsAdded()
        this.getAccountsAddedSummary()
    },
    methods:{
        getAccountsAdded() {
            this.$http.get('/accounts/get_AccountsAdded/')
            .then((response) => {
                this.accountsAdded = response.data
                this.series[0].data = response.data.data
                this.chartOptions.xaxis.categories = this.accountsAdded.dateNames
                this.loaded = true
            })
        },
        getAccountsAddedSummary() {
            this.$http.get('/widgets/Get_AccountsAddedSummary/')
            .then((response) => {
                this.accountsAddedSummary = response.data
            })
        }
    }
}
</script>

<style scoped>
.card-bordered {
    width: 600px;
    padding: 10px;
}
</style>